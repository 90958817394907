import axios from 'axios';

export function setupAxios(username = '') {
  let baseUrl = '';
  const lexUsers = [
    "rcbaquiano",
    "hmjandusay",
    "adbondoc",
    "jobaqs",
    "gdpangan",
    "cavpalattao",
    "alysonn",
    "kmesan",
    "aalagaya",
    "aldengo99@gmail.com"
  ]

  if (/demo/.test(username)) {
    baseUrl = 'https://demo-caes-api';
  } else {
    switch (username) {
      case 'arielagni':
      case 'katunying':
      case 'jasmin.prejillana':
        baseUrl = 'api_domain'
        break;
      case 'jasmin.prejillana@fhaoassociates.com':
        baseUrl = 'https://fhao-caes-api2';
        break;
      case 'remelyn.talosig@fhaoassociates.com':
        baseUrl = 'https://fhao-caes-api3';
        break;
      case 'marylin.arcilla@fhaoassociates.com':
        baseUrl = 'https://fhao-caes-api4';
        break;
      default:
        baseUrl = lexUsers.includes(username) ? 
          'https://lex-caes-api' : 
          'https://caes-tenacy-api'
        break;
    }
  }

  axios.defaults.baseURL =
    baseUrl === 'api_domain' ?
    process.env.REACT_APP_API_DOMAIN :
    `${baseUrl}${process.env.REACT_APP_DOMAIN}`;
}
